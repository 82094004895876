body {
  --primary: hsl(220, 65%, 33%);
  --secondary: #1d428a;
}
/* remove purchase dib */
.svc-creator__banner {
  display: none !important;
}

.svc-string-editor__content .svc-string-editor .sv-string-editor span {
  color: #1d428a;
}
/* module header color */
.sd-header__text h3 {
  color: #1d428a;
}

/* preview button color */
.sd-btn--action {
  color: #1d428a;
}
.sv-action__content input {
  background-color: #1d428a;
  color: #ffffff;
}
.svc-btn {
  background-color: #1d428a;
}
.svc-btn .svc-text {
  color: #ffffff;
}
/* removing focus button border */
.sv-button-group:focus-within {
  border: none;
}

.svc-tabbed-menu-item--selected {
  box-shadow: inset 0px -2px 0px var(--primary, #1d428a);
}
.svc-tabbed-menu-item--selected:hover,
.svc-tabbed-menu-item--selected:focus {
  box-shadow: inset 0px -2px 0px var(--primary, #1d428a);
}

/* input  */
.sd-input {
  border: 1px solid rgb(209 213 219);
  box-shadow: none;
  background-color: none;
}
.sd-input:focus {
  box-shadow: none;
}

.svc-json-editor-tab__content-area {
  min-height: 30rem;
  padding: 1rem;
}
/* remove dropdown focus css */
.spg-input:focus,
.spg-input.spg-dropdown:focus,
.spg-input.spg-dropdown:focus-within {
  border: none;
}
