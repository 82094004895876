.sd-body .sd-body--empty {
  display: none !important;
}
.sd-root-modern {
  background-color: rgb(203 213 225);
}
.sd-body.sd-body--static {
  margin: 0 !important;
  max-width: 100% !important;
}
.sd-body.sd-body--static .sd-body__page {
  padding: 1rem !important;
}
.sd-body__navigation .sd-btn {
  font-size: 0.875rem !important; /* 14px */
  line-height: 1.25rem !important; /* 20px */
  font-weight: 400 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.sd-btn--action:not(:disabled):focus {
  color: #fff !important;
  background-color: #1d428a !important;
}
