table {
  border-collapse: collapse;
  width: 100%;
}

table th,
table td {
  padding: 8px;
}
.arrow-img {
  transform: rotate(270deg);
}
