@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ActionNBA-Light-Web {
  font-family: "Action NBA  Web";

  font-weight: 300;

  font-style: normal;

  font-stretch: normal;
}

.ActionNBA-LightItalic-Web {
  font-family: "Action NBA  Web";

  font-weight: 300;

  font-style: italic;

  font-stretch: normal;
}

.ActionNBA-Medium-Web {
  font-family: "Action NBA  Web";

  font-weight: 500;

  font-style: normal;

  font-stretch: normal;
}

.ActionNBA-MediumItalic-Web {
  font-family: "Action NBA  Web";

  font-weight: 500;

  font-style: italic;

  font-stretch: normal;
}

.ActionNBA-Bold-Web {
  font-family: "Action NBA  Web";

  font-weight: 700;

  font-style: normal;

  font-stretch: normal;
}

.ActionNBA-BoldItalic-Web {
  font-family: "Action NBA  Web";

  font-weight: 700;

  font-style: italic;

  font-stretch: normal;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-Light-Web.eot");

  src: url("assets/Fonts/ActionNBA-Light-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-Light-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-Light-Web.woff") format("woff");

  font-weight: 300;

  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-LightItalic-Web.eot");

  src: url("assets/Fonts/ActionNBA-LightItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-LightItalic-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-LightItalic-Web.woff") format("woff");

  font-weight: 300;

  font-style: italic;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-Medium-Web.eot");

  src: url("assets/Fonts/ActionNBA-Medium-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-Medium-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-Medium-Web.woff") format("woff");

  font-weight: 500;

  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-MediumItalic-Web.eot");

  src: url("assets/Fonts/ActionNBA-MediumItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-MediumItalic-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-MediumItalic-Web.woff") format("woff");

  font-weight: 500;

  font-style: italic;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-Bold-Web.eot");

  src: url("assets/Fonts/ActionNBA-Bold-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-Bold-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-Bold-Web.woff") format("woff");

  font-weight: 700;

  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";

  src: url("assets/Fonts/ActionNBA-BoldItalic-Web.eot");

  src: url("assets/Fonts/ActionNBA-BoldItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("assets/Fonts/ActionNBA-BoldItalic-Web.woff2") format("woff2"),
    url("assets/Fonts/ActionNBA-BoldItalic-Web.woff") format("woff");

  font-weight: 700;

  font-style: italic;
}
