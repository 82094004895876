.required:after {
  content: " *";
  color: red;
}

.minHeight {
  min-height: 50.5px;
}

.labelWidth {
  width: 20%;
}

.textAreaHeight {
  min-height: 2.5rem;
}

.tagSelector ul {
  max-height: 150px !important;
}

.bwb-programs {
  background-color: #f0d900 !important;
}

.jr-nba-us-programs {
  background-color: #27a2cb !important;
}

.nba-basketball-school-programs {
  background-color: #7000ff !important;
}

.nba-academy-programs {
  background-color: #d129bb !important;
}

.jr-nba-international-programs {
  background-color: #00d18c !important;
}

.other {
  background-color: #fe4917 !important;
}

.day-cell-view {
  min-height: 10rem !important;
}

/* .fc .fc-daygrid-event-harness-abs, */
.fc .fc-daygrid-event-harness {
  cursor: pointer !important;
}
.day-cell-view .fc-event-title {
  text-overflow: ellipsis;
}

.day-cell-view .fc-popover-body {
  height: 15rem !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}
@media screen and (max-width: 500px) {
  .fc .fc-toolbar-title {
    font-size: 1rem !important;
  }
  .fc .fc-button {
    padding: 5px !important;
  }
}
@media screen and (max-width: 415px) {
  .fc .fc-toolbar-title {
    font-size: 0.7rem !important;
  }
}
.fc-day-today {
  background-color: rgb(229 231 235) !important;
}
.fc-popover {
  left: 40% !important;
  top: 20% !important;
  /* width: 20rem !important; */
  box-shadow: 0px 5px 10px #333 !important;
}

.closeImpersonate {
  cursor: pointer;
  top: -7px;
  position: absolute;
}
.dropdown-selct-overlap .css-1nmdiq5-menu {
  z-index: 2000 !important;
}
.multi-select-class .chip {
  white-space: break-spaces !important;
}
h3.m_title {
  display: block;
}

h3.m_title:first-letter {
  text-transform: uppercase;
}
.comment-icon-shadow {
  box-shadow: 0 0 15px #ddd;
}
.category-side-nav {
  width: 90% !important;
  height: 100% !important;
}
.pie-chart-height .recharts-wrapper {
  height: 255px !important;
}

.bg-reply {
  background: rgb(231 229 228) !important;
}

/* loader css for sidebar */

.loader {
  width: 25px;
  height: 25px;
  border: 3px solid #1d428a;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.isNewProgram {
  display: inline-block;
  vertical-align: middle;

  background: url("../assets/huddle_new_ic.png") 0 0 no-repeat;
  background-size: 15px;
  padding-left: 1.3rem;
  background-position: 2px;

  width: 100%;
}
.isNewProgram .fc-event-title::after {
  content: " New ";
  background-color: #000;
  color: #ffff;
  margin-left: 0.5rem;
  font-size: 10px;
  padding: 0rem 0.2rem 0rem 0.2rem !important;

  border-radius: 2px;
}
.isNewProgram .fc-h-event .fc-event-title {
  display: flex !important;
}
.date-picker .react-datepicker-wrapper {
  width: 100% !important;
}
.program-bar-chart svg {
  width: 850px !important;
  /* width: 550px !important; */
}
.program-bar-chart-parent .recharts-responsive-container {
  width: 650px !important;
}
.program-bar-chart {
  width: 650px !important;
}
.program-bar-chart .recharts-text tspan {
  font-size: 11px;
}
.assign-me-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.time-expire-icon {
  color: #1d428a !important;
  fill: #1d428a !important;
}
