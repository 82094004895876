/* PDF css */
div.page-footer {
  text-align: center;
  height: 50px;
  font-size: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-pdf {
  height: 20px;
  width: 100%;
}

div.page-footer p {
  margin: 0;
}

.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 100%;
  page-break-after: always;
  margin: 0;
  transform: translate(-50%, -50%);
}
.pdf-table-th,
.pdf-table-td {
  padding: 0 !important;
}

table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

div.footer-info,
div.page-footer {
  display: none;
  height: 20px;
}

@media print {
  @page {
    size: A4;
    margin: 0 !important;
  }
  .pagebreak {
    page-break-before: always;
  }

  .watermark {
    display: block;
    counter-increment: page;
    position: fixed;
  }

  div.page-footer,
  div.footer-info {
    display: block;
  }
}
